import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import { REGEXP } from "../../utilities/validators/inputValidators";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// utils-import
import { randomUUID } from "../../services/randomUUID";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import VerifiedSettlementAccountBlock from "./VerifiedSettlementAccountBlock/VerifiedSettlementAccountBlock";
import { TotpModal } from "./TotpModal/TotpModal";

//styles-import
import "../../UI/TextField/TextField.scss";

const initialValues = {
  name: "",
  action: "",
  account_number: "",
  consumer_urn: "",
  ifsc: "",
};

const {
  laAccountRegex,
  consumerUrnRegex,
  settlementAccountNameRegex,
  ifscRegex,
} = REGEXP;
// const validationSchema = Yup.object({
//   action: Yup.object().shape({
//     value: Yup.string().required("Please select an option"),
//     label: Yup.string().required("Please select an option"),
//   }),
//   account_number: Yup.string()
//     .matches(accountNumberRegex)
//     .required("Account Number is required"),
//   consumer_urn: Yup.string()
//     .matches(consumerUrnRegex)
//     .required("Consumer URN is required"),
//   name: Yup.string().matches(nameFieldRegex).required("Name is required"),
//   ifsc: Yup.string()
//     .matches(ifscRegex, "Invalid IFSC code")
//     .required("IFSC code is required"),
// });

const validationSchema = Yup.object({
  action: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  // Define initial schema for fields under ADD action
  account_number: Yup.string().when("action", {
    is: (val) => val && val.label === "ADD",
    then: () =>
      Yup.string()
        .matches(laAccountRegex, "Invalid account number")
        .required("Account Number is required"),
  }),
  name: Yup.string().when("action", {
    is: (val) => val && val.label === "ADD",
    then: () =>
      Yup.string()
        .matches(settlementAccountNameRegex, "Invalid name format")
        .required("Name is required"),
  }),
  ifsc: Yup.string().when("action", {
    is: (val) => val && val.label === "ADD",
    then: () =>
      Yup.string()
        .matches(ifscRegex, "Invalid IFSC code")
        .required("IFSC code is required"),
  }),
  consumer_urn: Yup.string()
    .matches(consumerUrnRegex, "Invalid Consumer URN")
    .required("Consumer URN is required"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementAccount = () => {
  //   const [companyOptions, setCompanyOptions] = useState([""]);
  //   const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);

  // &  state's for Response block
  const [validateResponseDetails, setValidateResponseDetails] = useState(null);
  const [showValidateResponseDetails, setShowValidateResponseDetails] =
    useState(false);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  let actionDropDownValues = [
    {
      value: "ADD",
      label: "ADD",
    },
    {
      value: "DEACTIVATE",
      label: "DEACTIVATE",
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const openTotpModal = () => {
    setShowTotpModal(true);
  };

  const settlementAccountAction = (formik) => {
    const { values, action } = formik;
    let settlementAccountSetupPayload = {
      reference_id: randomUUID(),
      action: values?.action?.label,
      consumer_urn: values.consumer_urn,
      account_number: values.account_number,
      ifsc: values.ifsc,
      name: values.name,
      provider_code: "yesb",
    };

    if (values?.action?.label === "DEACTIVATE") {
      settlementAccountSetupPayload = {
        reference_id: randomUUID(),
        action: values?.action?.label,
        consumer_urn: values.consumer_urn,
        provider_code: "yesb",
      };
    }

    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.baseUrl +
        paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.endpoint,
      settlementAccountSetupPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails(response.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Success"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponseDetails(true);

        // * Remove configure block
        setShowValidateResponseDetails(false);

        formik.resetForm();
      })
      .catch((error) => {
        setResponseDetails(error.response.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponseDetails(true);

        // * Remove configure block
        setShowValidateResponseDetails(false);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      //* Reset Verified block & Response Block
      setShowValidateResponseDetails(false);
      setShowResponseDetails(false);

      // FIX : workflow api implementation
      action.setSubmitting(true);
      APIConfig.API_Client.post(
        "https://workflow.decpay.in/webhook/validate/settlement_account",
        {
          account_number: values.account_number,
          ifsc: values.ifsc,
          name: values.name,
        },
        { cancelToken: source.token }
      )
        .then((response) => {
          setValidateResponseDetails(response.data);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={response?.data?.message || "Success"}
            />,
            document.getElementById("snackbar")
          );

          setShowValidateResponseDetails(true);

          console.log(
            "🚀 🚀 🚀 ~ file: SettlementAccount.js:250 ~ .then ~ response:",
            response
          );
        })
        .catch((error) => {
          setValidateResponseDetails(error?.response?.data || error?.message);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );
          setShowValidateResponseDetails(false);

          console.log(
            "🚀 🚀 🚀 ~ file: SettlementAccount.js:254 ~ SettlementAccount ~ error:",
            error
          );
        })
        .finally(() => {
          action.setSubmitting(false);
        });

      // TODO: first use validate workflow API
      // submitHandler(values, action);

      // let settlementAccountSetupPayload = {
      //   reference_id: randomUUID(),
      //   action: values?.action?.label,
      //   consumer_urn: values.consumer_urn,
      //   account_number: values.account_number,
      //   ifsc: values.ifsc,
      //   name: values.name,
      //   provider_code: "yesb",
      // };

      // if (values?.action?.label === "DEACTIVATE") {
      //   settlementAccountSetupPayload = {
      //     reference_id: randomUUID(),
      //     action: values?.action?.label,
      //     consumer_urn: values.consumer_urn,
      //     provider_code: "yesb",
      //   };
      // }

      // action.setSubmitting(true);
      // APIConfig.API_Client.post(
      //   paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.baseUrl +
      //     paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.endpoint,
      //   settlementAccountSetupPayload,
      //   { cancelToken: source.token }
      // )
      //   .then((response) => {
      //     setResponseDetails(response.data);
      //     setShowResponseDetails(true);
      //     ReactDOM.render(
      //       <SnackbarMessage
      //         msgtype="success"
      //         msg={response?.data?.message || "Success"}
      //       />,
      //       document.getElementById("snackbar")
      //     );

      //     action.resetForm();
      //   })
      //   .catch((error) => {
      //     setResponseDetails(error.response.data);
      //     setShowResponseDetails(true);

      //     ReactDOM.render(
      //       <SnackbarMessage
      //         msgtype="Error"
      //         msg={error?.response?.data?.message || "Something went wrong!"}
      //       />,
      //       document.getElementById("snackbar")
      //     );
      //   })
      //   .finally(() => {
      //     action.setSubmitting(false);
      //   });
    },
  });

  //   const getCompanyOptions = () => {
  //     APIConfig.API_Client.post(
  //       apiEndpointList.GET_COMPANY_COMMON_NAME_LIST.baseUrl +
  //         apiEndpointList.GET_COMPANY_COMMON_NAME_LIST.endpoint,
  //       {},
  //       {
  //         cancelToken: source.token,
  //       }
  //     )
  //       .then((response) => {
  //         const options = response.data.map((company) => ({
  //           value: company.id,
  //           label: company.common_name,
  //         }));
  //         setCompanyOptions(options);
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         ReactDOM.render(
  //           <SnackbarMessage
  //             msgtype="error"
  //             msg={"Failed to load Company Name Options"}
  //           />,
  //           document.getElementById("snackbar")
  //         );
  //       });
  //   };

  //   useEffect(() => {
  //     getCompanyOptions();
  //   }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          {/* <div className="ui-divider"></div> */}
          <div className="ui-form-content ui-divider">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              {/* <TextFieldSelect
                id="company_common_name"
                name="company_common_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_common_name", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("company_common_name", true)
                }
                value={formik.values.company_common_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company Common Name"
                required={true}
                isLoading={isLoading}
                placeholder="Select Common Name"
                isformatOptionLabel={true}
              /> */}

              <TextFieldInput
                id="consumer_urn"
                name="consumer_urn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.consumer_urn}
                touched={formik.touched.consumer_urn}
                error={formik.errors.consumer_urn}
                placeholder="Enter consumer URN"
                label="Consumer URN"
                required={true}
                disabled={false}
              />

              <TextFieldSelect
                id="action"
                name="action"
                onChange={(selectedOption) =>
                  formik.setFieldValue("action", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("action", true)}
                value={formik.values.action}
                options={actionDropDownValues}
                noOptionsMessage={() => "No such action exists"}
                label="Action"
                required={true}
                // isLoading={isLoading}
                placeholder="Select action"
                isformatOptionLabel={false}
                isClearable={false}
              />
            </div>

            {formik?.values?.action?.label === "ADD" ? (
              <>
                {/* 2 */}
                <div className="ui-form-inputs-section">
                  <TextFieldInput
                    id="account_number"
                    name="account_number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.account_number}
                    touched={formik.touched.account_number}
                    error={formik.errors.account_number}
                    placeholder="Enter account number"
                    label="Account Number"
                    required={true}
                    disabled={false}
                  />

                  <TextFieldInput
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    touched={formik.touched.name}
                    error={formik.errors.name}
                    placeholder="Enter name"
                    label="Name"
                    required={true}
                    disabled={false}
                  />
                </div>
                {/* 3 */}
                <div className="ui-form-inputs-section">
                  <TextFieldInput
                    id="ifsc"
                    name="ifsc"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ifsc}
                    touched={formik.touched.ifsc}
                    error={formik.errors.ifsc}
                    placeholder="Enter IFSC"
                    label="IFSC"
                    required={true}
                    disabled={false}
                  />
                </div>
              </>
            ) : null}

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : "disabled"
                }`}
                type="submit"
                // FIX:uncomment the below
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          // submitCallback={editNewUser}
          submitAfterTotp={(formik) => settlementAccountAction(formik)}
          formik={formik}
        />
      ) : null}

      {/* Settlement Account API hit block on validate "Success" */}
      {/* {true ? ( */}
      {showValidateResponseDetails ? (
        <VerifiedSettlementAccountBlock submitHandler={submitHandler} />
      ) : null}
      {/* Settlement account Response Block */}
      {showResponseDetails && (
        <ResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};

export default SettlementAccount;
