import React from "react";
// styles import
import "./ToggleCategory.scss";

export default function ToggleCategory({
  id = "toggle",
  label = "Label Text here",
  isOn,
  toggleSwitch,
}) {
  return (
    <div className="toggle-wrapper">
      <p className="toggle-input-label">{label}</p>

      <div className="toggle-switch">
        <input
          id={id}
          type="checkbox"
          value={isOn}
          onChange={toggleSwitch}
          className="toggle-input"
        />
        <label htmlFor="toggle" className="toggle-label">
          <span className="toggle-thumb"></span>
        </label>
      </div>
    </div>
  );
}
