import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//ui-component-import
import FormHeading from "../../../UI/FormHeading/FormHeading";
import FormTitle from "../../../UI/FormTitle/FormTitle";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

// utils imports
import { randomUUID } from "../../../services/randomUUID";
import { REGEXP } from "../../../utilities/validators/inputValidators";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

//context-imports
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";

//API-imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";

// styles import
import "./SettlementAccountPA.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const initialValues = {
  // consumer_urn: "",
  name: "",
  account_number: "",
  ifsc: "",
};

const { laAccountRegex, settlementAccountNameRegex, ifscRegex } = REGEXP;

const validationSchema = Yup.object().shape({
  account_number: Yup.string()
    .matches(laAccountRegex, "Must be a valid account number")
    .required("Account number is required"),
  ifsc: Yup.string()
    .matches(ifscRegex, "Must be a valid IFSC code")
    .required("IFSC code is required"),
  name: Yup.string()
    .matches(
      settlementAccountNameRegex,
      "Name must be alphanumeric with spaces"
    )
    .min(3, "Name must be at least 3 characters")
    .required("Name is required"),
});

const SettlementAccountPA = () => {
  // ^ context
  const { toDMO, consumerURN, toCompanyDetails, companyID } = useContext(
    OnBoardCompanyPAContext
  );

  // & state
  const [showOnFailedSettlementAccount, setShowOnFailedSettlementAccount] =
    useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      let settlementAccountSetupPayload = {
        reference_id: randomUUID(),
        action: "ADD",
        consumer_urn: consumerURN,
        account_number: values.account_number,
        ifsc: values.ifsc,
        name: values.name,
        provider_code: "yesb",
        is_master_settlement_account: true,
      };

      setShowOnFailedSettlementAccount(false);

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.baseUrl +
          paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.endpoint,
        settlementAccountSetupPayload,
        { cancelToken: source.token }
      )
        .then((response) => {
          ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={response.data.message} />,
            document.getElementById("snackbar")
          );

          // * To last (4th) step of onboarding
          // toSummary();
          toDMO();

          //   action.resetForm();
        })
        .catch((error) => {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );

          setShowOnFailedSettlementAccount(true);
        })
        .finally(() => {
          action.setSubmitting(false);
        });
    },
  });

  // Send Credentials API
  const sendCredentialsAPI = () => {
    APIConfig.API_Client.post(
      paAPIEndpoints.SEND_ONBOARDING_CREDENTIALS.baseUrl +
        paAPIEndpoints.SEND_ONBOARDING_CREDENTIALS.endpoint,
      {
        reference_id: randomUUID(),
        company_id: companyID,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        // * snackbar for success
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Credentials sent Successfully"}
          />,
          document.getElementById("snackbar")
        );

        // * To 1st step of onboarding
        toCompanyDetails();
      })
      .catch((error) => {
        // * snackbar for error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          {/* form-heading */}
          <FormHeading headingText="Fill account details to continue" />
          {/* form-main */}
          <div className="ui-form-content">
            {/* COMPANY DETAILS */}
            <FormTitle titleText="Add Settlement Account" />

            {/* # 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="consumer_urn"
                name="consumer_urn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={consumerURN || ""}
                touched={formik.touched.consumer_urn}
                error={formik.errors.consumer_urn}
                placeholder="Enter Consumer URN"
                label="Consumer URN"
                required={true}
                disabled={true}
              />

              <TextFieldInput
                id="name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                touched={formik.touched.name}
                error={formik.errors.name}
                placeholder="Enter Name"
                label="Name"
                required={true}
                disabled={false}
              />
            </div>

            {/* # 2 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="account_number"
                name="account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_number}
                touched={formik.touched.account_number}
                error={formik.errors.account_number}
                placeholder="Enter Account Number"
                label="Account Number"
                required={true}
                disabled={false}
              />

              <TextFieldInput
                id="ifsc"
                ifsc="ifsc"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ifsc}
                touched={formik.touched.ifsc}
                error={formik.errors.ifsc}
                placeholder="Enter IFSC"
                label="IFSC"
                required={true}
                disabled={false}
              />
            </div>
          </div>
          {/* form-main-ends */}

          {/* submit-button-starts */}
          <div className="ui-settlement-button-container">
            <button
              className={`${
                formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : ""
              }`}
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
              //! onClick={goToModuleProviderPA}
            >
              {formik.isSubmitting ? "Loading..." : "Submit"}
            </button>
          </div>
          {/* submit-button-ends */}
        </div>
      </form>

      <div className="ui-settlement-button-container container-flex">
        <p className="">
          The settlement account was skipped. <br /> Please add the account
          separately
        </p>
        <button className={`${"active"}`} type="button" onClick={toDMO}>
          Skip this step
        </button>
      </div>

      {/* Summary backup btn */}
      {/* consumerURN */}
      {showOnFailedSettlementAccount ? (
        <div className="ui-settlement-button-container container-flex">
          <p className="error-message">
            Settlement Account addition failed. Please generate credentials by
            clicking on Generate/Send Credentials and <br /> add the Settlement
            Account separately.
          </p>
          <button
            className={`${"active"}`}
            type="button"
            onClick={sendCredentialsAPI}
          >
            Generate Credentials
          </button>
        </div>
      ) : null}

      {/* Multi Attempt */}
      {/* <MultiAttemptPA companyID={companyID}/> */}
    </>
  );
};

export default SettlementAccountPA;
