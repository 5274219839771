import React, { useState, useRef, useEffect } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./DateRangePicker.scss";

const DateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  label,
  required,
  placeholder = "Select date range",
  touched,
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);

  const [showYearSelector, setShowYearSelector] = useState(false);

  // Format date for display
  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // Format display value
  const displayValue =
    startDate && endDate
      ? `${formatDate(startDate)} - ${formatDate(endDate)}`
      : "";

  // Close calendar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle date selection
  const handleDateSelect = (date) => {
    const selectedDate = new Date(date);
    selectedDate.setUTCHours(0, 0, 0, 0);
    if (!startDate || (startDate && endDate)) {
      onStartDateChange(selectedDate);
      onEndDateChange(null);
    } else {
      if (date < startDate) {
        onStartDateChange(selectedDate);
        onEndDateChange(startDate);
      } else {
        onEndDateChange(selectedDate);
      }
      setIsOpen(false);
    }
  };

  // Generate calendar days
  const generateCalendarDays = (year, month) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];

    // Add empty cells for days before first of month
    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(null);
    }

    // Add days of month
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(new Date(year, month, i));
    }

    return days;
  };

  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const days = generateCalendarDays(currentYear, currentMonth);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Generate array of years (current year ± 10 years)
  const years = Array.from({ length: 21 }, (_, i) => currentYear - 10 + i);

  return (
    <div
      className="ui-form-input-section ui-form-content-input"
      ref={datePickerRef}
    >
      <div
        className={`ui-form-input-box date-range-input ${
          touched && error ? "input-error" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <input
          type="text"
          readOnly
          value={displayValue}
          placeholder={placeholder}
          className="date-range-display"
        />
        <CalendarTodayIcon className="calendar-icon" />
      </div>

      <label className="ui-label">
        {label} {required && <span className="required">*</span>}
      </label>

      {touched && error && (
        <img
          src="/images/invalid-input.svg"
          alt="Invalid Input"
          className="ui-invalid-field-icon"
        />
      )}

      {isOpen && (
        <div className="calendar-dropdown">
          <div className="calendar-header">
            <button
              onClick={() => {
                if (!showYearSelector) {
                  setCurrentYear((prev) => prev - 1);
                }
              }}
              title="Previous Year"
            >
              <KeyboardDoubleArrowLeftIcon />
            </button>
            <button
              onClick={() => {
                if (currentMonth === 0) {
                  setCurrentMonth(11);
                  setCurrentYear((prev) => prev - 1);
                } else {
                  setCurrentMonth((prev) => prev - 1);
                }
              }}
              title="Previous Month"
            >
              <KeyboardArrowLeftIcon />
            </button>

            <div className="calendar-selectors">
              <span
                onClick={() => setShowYearSelector(!showYearSelector)}
                className="year-month-selector"
              >
                {showYearSelector
                  ? "Select Year"
                  : `${monthNames[currentMonth]} ${currentYear}`}
              </span>

              {showYearSelector && (
                <div className="year-selector">
                  {years.map((year) => (
                    <button
                      key={year}
                      onClick={() => {
                        setCurrentYear(year);
                        setShowYearSelector(false);
                      }}
                      className={year === currentYear ? "selected" : ""}
                    >
                      {year}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <button
              onClick={() => {
                if (currentMonth === 11) {
                  setCurrentMonth(0);
                  setCurrentYear((prev) => prev + 1);
                } else {
                  setCurrentMonth((prev) => prev + 1);
                }
              }}
              title="Next Month"
            >
              <KeyboardArrowRightIcon />
            </button>
            <button
              onClick={() => {
                if (!showYearSelector) {
                  setCurrentYear((prev) => prev + 1);
                }
              }}
              title="Next Year"
            >
              <KeyboardDoubleArrowRightIcon />
            </button>
          </div>

          <div className="calendar-days">
            <div className="weekdays">
              <span>Su</span>
              <span>Mo</span>
              <span>Tu</span>
              <span>We</span>
              <span>Th</span>
              <span>Fr</span>
              <span>Sa</span>
            </div>
            <div className="days-grid">
              {days.map((date, index) => {
                if (!date)
                  return <span key={`empty-${index}`} className="empty-cell" />;

                const isSelected =
                  startDate &&
                  endDate &&
                  date >= new Date(startDate) &&
                  date <= new Date(endDate);

                const isStart =
                  startDate &&
                  date.toDateString() === new Date(startDate).toDateString();
                const isEnd =
                  endDate &&
                  date.toDateString() === new Date(endDate).toDateString();

                return (
                  <button
                    key={date.toDateString()}
                    onClick={() => handleDateSelect(date)}
                    className={`day-cell ${isSelected ? "selected" : ""} 
                      ${isStart ? "start" : ""} ${isEnd ? "end" : ""}`}
                  >
                    {date.getDate()}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
