import React from "react";

//component-import
import ToolTip from "../ToolTip/ToolTip";

//styles-import
import "./TextField.scss";

const TextFieldInput = ({
  id,
  name,
  onChange,
  onBlur,
  value,
  touched,
  error,
  placeholder,
  label,
  type = "text",
  required,
  disabled,
  maxLength,
  isToolTip,
  customLabelWidth = false,
  vertical = false,
  helperText = "",
}) => (
  <div
    className={`ui-form-input-section ui-form-content-input ${
      vertical && "w-100"
    }`}
  >
    <div className="ui-form-element-control-default">
      <input
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        className={`ui-form-input-box ${touched && error ? "input-error" : ""}`}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        aria-autocomplete="none"
      />
      {helperText && <p className="extra-hint">{helperText || "-"}</p>}
    </div>
    <label
      className={"ui-label"}
      style={{ width: `${customLabelWidth ? customLabelWidth : null}%` }}
      htmlFor={id}
    >
      {label}{" "}
      <ToolTip text={isToolTip} requiredField={required} isToolTip={isToolTip}>
        {isToolTip && (
          <div className="ui-info-icon">
            <img src="/images/info-icon.svg" alt="" />
          </div>
        )}
      </ToolTip>
    </label>
    {touched && error ? (
      <img
        src="/images/invalid-input.svg"
        alt="Invalid Input"
        className={
          helperText ? "ui-invalid-field-icon-helper" : "ui-invalid-field-icon"
        }
      />
    ) : null}
  </div>
);

export default TextFieldInput;
